html {
  font-size: 62.5%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #000;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: white;
}